import React from 'react'
import { graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import sortBy from 'lodash.sortby'
import PubSub from 'pubsub-js'

import { spacing } from '@papertrailio/react-web-core/style/theme'
import { ReactComponent as WhiteIcon } from '@papertrailio/react-web-core/img/pt-white-icon.svg'
import Button from '@papertrailio/react-web-core/components/Button'
import ContentContainer from '@papertrailio/react-web-core/components/ContentContainer'
import ContentAnchor from '@papertrailio/react-web-core/components/ContentAnchor'
import * as productsList from '@papertrailio/react-web-core/utils/productsList'
import getWebAppBaseUrl from '@papertrailio/react-web-core/utils/getWebAppBaseUrl'
import ProductListItem from '@papertrailio/react-web-core/components/product/ProductListItem'

import Layout from '../components/Layout'
import Link from '../components/Link'
import Helmet from '../components/Helmet'
import slug from '../utils/slug'


const ContentContainerStyled = styled(ContentContainer)`
  margin-bottom: ${spacing.base * 4}px;
`

const TextContainer = styled.div`
  max-width: 60%;

  @media only screen and (max-width: 750px) {
    max-width: 100%;
  }
`

const List = styled.ul`
  margin-left: 0;
  margin-bottom: ${spacing.base * 2}px;
`

const PTLogo = styled(WhiteIcon).attrs({ fill: '#fff' })`
  height: 15px;
  width: 20px;
  margin-bottom: 6px;
  margin-right: 6px;
`

class ImportListPage extends React.Component {
  pubSubToken

  constructor(props) {
    super(props)

    this.state = {
      productsList: [],
      quantity: 0,
      listFetched: false,
    }
  }

  componentDidMount = () => {
    if (window) {
      this.pubSubToken = PubSub.subscribe(productsList.NOTIFY_CHANGES, () => {
        this._fetchList()
        this._fetchQuantity()
      })

      this._fetchList()
      this._fetchQuantity()
    }
  }

  _fetchList = () => {
    productsList.list().then(products => {
      const sortedProducts = sortBy(products, ['name'])

      this.setState({
        productsList: sortedProducts,
        listFetched: true,
      })
    })
  }

  _fetchQuantity = () =>
    productsList.getQuantity().then(quantity => {
      this.setState({
        quantity,
      })
    })

  render = () => (
    <Layout>
      <Helmet
        title="Search and explore the Product Directory"
        description="The Papertrail Product Directory is a resource which you can use to lookup product information. All the information comes direct from our manufacturer partners."
      />

      <ContentContainerStyled>
        <ContentAnchor />

        <TextContainer>
          <h1>Product Import List</h1>
          {this.state.listFetched && (
            <>
              {!this.state.quantity && (
                <>
                  <p>
                    Once you have added products to your Import List, they will be
                    displayed below.
                  </p>
                  <Button
                    theme="primaryRed"
                    to={`/`}
                    Link={Link}
                  >
                    Search for products
                  </Button>
                </>
              )}
              {!!this.state.quantity && (
                <p>
                  The products listed below are ready for importing directly
                  into Papertrail.
                </p>
              )}
            </>
          )}
        </TextContainer>

        {!!this.state.productsList.length && (
          <List>
            {this.state.productsList.map(product => (
              <ProductListItem
                key={product.id}
                product={{ ...product, unique: false }}
                manufacturer={product.manufacturer}
                listPagePath="/import-list"
                onEditQuantity={quantity => {
                  productsList.set(product, quantity)
                }}
                quantity={product.__quantity}
                onClick={() =>
                  navigate(`/${slug(product.manufacturer)}/product-details/`, {
                    state: {
                      isModal: true,
                      listPagePath: '/import-list',
                      product,
                    },
                  })
                }
              />
            ))}
          </List>
        )}

        {this.state.listFetched &&
          !!this.state.quantity && (
            <Button
              theme="primaryRed"
              wide
              onClick={productsList.clear}
              // to={`${getWebAppBaseUrl(data.site.siteMetadata.appUrl)}/imports/new?productIds=${product.id}`}
              to={`${getWebAppBaseUrl(
                this.props.data.site.siteMetadata.appUrl
              )}/imports/new?productIds=${this.state.productsList
                .reduce((accummulator, product) => {
                  accummulator.push(
                    ...new Array(product.__quantity).fill(product.id)
                  )
                  return accummulator
                }, [])
                .join(',')}`}
              target="_blank"
              Link={Link}
            >
              <PTLogo />
              Import {this.state.quantity} Products to Papertrail
            </Button>
          )}
      </ContentContainerStyled>
    </Layout>
  )
}

ImportListPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ImportListPage

export const query = graphql`
  query ImportListPageQuery {
    site {
      siteMetadata {
        appUrl {
          production
          develop
        }
      }
    }
  }
`
